import React, { useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqSection from "../components/FaqSection"
import StaticPageHeader from "../components/StaticPageHeader"
import {hubbleClassic} from "../services/data";

const Faq = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/faq/",
      "@type": "WebPage",
      "name": "Hubble Contact Lenses & Eye Glasses Frequently Asked Questions",
      "description": "Discover answers to your frequently asked questions about Hubble prescription daily contacts, frames and glasses, and prescription sunglasses. Find information on orders, monthly and weekly prescription contact subscriptions, prescriptions, online vision tests, and more.",
      "publisher": {
          "@type": "Organization",
          "name": "Hubble Contacts"
      }
    },
    {


      "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Where can I find reviews about Hubble?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Look no further - visit our Contact Lens product category page to see what customers have to say about Hubble's affordable daily contact lenses! You can also learn more about our products by visiting the Hubble Resources Library for additional information, support and comprehensive eye care tips."
            }
          },
          {
            "@type": "Question",
            "name": "How does my contact lens subscription work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": `Our online contact lens subscription makes it super easy for you to get affordable daily contact lenses delivered right to your door—right when you need them. If you’re a new contact lens customer, you’ll pay just $1 (plus any applicable sales tax) for your first ${hubbleClassic.trialPeriodPairs} pairs of Hubble affordable daily contact lenses. Upon signing up, you will pick your preferred prescription contact lens refill cadence – the frequency with which you’d like to receive more eye contacts. Set it for contacts every 4 weeks, 6, weeks, 8 weeks—whatever works for you. After that, we’ll pack, prep and ship fresh affordable daily contacts based on your timing preferences. We charge your card on file each time your daily contact lenses are shipped, and you can cancel your prescription contacts subscription any time— right up until we ship your contact lenses and charge your card for the month.`
            }
          },
       {
            "@type": "Question",
            "name": "Can I change the frequency of my shipments?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes! We designed our Member Portal to allow YOU to easily manage your delivery cadence. You can even select a specific date when you’d like us to ship your contacts. When we say personalized just for you, we mean it."
            }
          },
      {
            "@type": "Question",
            "name": "Will my contact lens subscription just stop when my prescription expires?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "It depends. If you would like to renew your contact lens prescription online, we can refer you to an online vision test. We also may reach out to your eye care provider, which will help us determine whether we can continue your contact lens subscription. In addition, you can also provide us with a copy of your updated prescription for contact lenses or reach out to us at help@hubblecontacts.com. If you do not provide us with a copy of your contact lens prescription, there is a chance that you may order and receive contact lenses other than those you were prescribed."
            }
          },
          {
            "@type": "Question",
            "name": "What is your return policy for Hubble?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Love it or we’ll cover it—that’s Hubble looking out for you! You can return any unopened boxes of contact lenses or frames, for any reason, within 14 days of receipt to receive a full refund. Additionally, you may exchange your contact lenses or glasses at no cost within 14 days of receipt if you have opened the box and found that they are different from what you ordered or are defective. The fastest way to initiate your return or exchange with Hubble is to contact us via chat or phone at +1-844-334-1640 to request a prepaid mailing label. You have 14 days to drop off your return at the shipping carrier before the mailing label is voided."
            }
          },
          {
            "@type": "Question",
            "name": "After the starter pack, when will my regular refill orders ship?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": `Your regular affordable daily contacts shipments will ship at least ${hubbleClassic.trialPeriod} days after your initial $1 contact lenses starter pack order is placed. It takes us a few days to contact your doctor, so online orders for contacts placed on Fridays and over the weekend will not ship until the following week.`
            }
          },
          {
            "@type": "Question",
            "name": "What happens if my contact lens prescription changes?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "If your prescription for contact lenses changes, let us know! We’ll communicate with your provider to see if we can update your subscription for affordable daily contact lenses."
            }
          },
          {
            "@type": "Question",
            "name": "Can I change my subscription with Hubble? How?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes! You are in control. We’ve made it super easy to make changes to your subscription of affordable daily contact lenses with Hubble, adjust the frequency of shipments, update your shipping address, and more—all from your Member Portal. Or, if you need help, contact our team via phone at +1-844-334-1640, chat, or by email at help@hubblecontacts.com."
            }
          },
      {
            "@type": "Question",
            "name": "Can I cancel my Hubble subscription?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Aw, we hate to see you go. Remember: you can always delay upcoming shipments of your affordable daily contact lenses or change your delivery cadence fright from your member portal. Yes, you can cancel your Hubble Contacts subscription at any time. The easiest way to cancel your Hubble Contacts subscription is through your online Member Portal. You can also cancel by chatting with a customer service agent or calling us at +1-844-334-1640. We’re available between 9 AM and 9 PM ET, seven days a week (excluding federal holidays)."
            }
          },
      {
            "@type": "Question",
            "name": "Where does Hubble contacts ship to?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We currently ship prescription contact lenses to the U.S. and its territories, including Puerto Rico, Guam, the U.S. Virgin Islands, and military APO/DPO/FPO addresses."
            }
          },
          {
            "@type": "Question",
            "name": "How long will it take for my order to arrive?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble uses USPS Standard Economy Shipping for regular shipments of prescription daily contact lenses, frames, and accessories, which generally takes between 5-7 business days. However, shipments to areas outside the continental United States may take longer. Note that it may take a little longer for your first order to arrive because our team contacts your doctor to dot the i’s and cross the t’s with your contact lens prescription. We’ll keep you updated via email on the status. At any time, for any order, customers can see their order status and tracking in our online Member Portal."
            }
          },
          {
            "@type": "Question",
            "name": "What is the cost of shipping?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Hubble $1 contact lens starter pack includes free shipping. After that, we charge $3 for shipping and handling (plus any applicable sales tax) on each contact lens delivery. All glasses, sunglasses, and accessories include free shipping."
            }
          },
          {
            "@type": "Question",
            "name": "Can I change the shipping method once my order has shipped?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No. You cannot modify the shipping method. Please note: we do not offer expedited shipping options."
            }
          },
          {
            "@type": "Question",
            "name": "What forms of payment does Hubble accept?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble accepts all major credit and debit cards. We also accept FSA or HSA payments (see below)."
            }
          },
          {
            "@type": "Question",
            "name": "Does Hublle accept vision care insurance as payment?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Unfortunately, Hubble doesn't work with insurance providers directly, but you can download your invoices from your Member Portal to apply for reimbursement from your insurance provider."
            }
          },
          {
            "@type": "Question",
            "name": "How do I get a receipt to submit to my vision care insurance provider?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You can download your receipt for your online contact lenses order from your Member Portal, contact us via chat or email us at help@hubblecontacts.com and we'll send you an invoice for your insurance provider."
            }
          },
         {
            "@type": "Question",
            "name": "Will I be charged sales tax?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We must apply state and local sales taxes to your Hubble contact lens subscription payments. The sales tax amount will depend on your current ZIP code and your Hubble subscription plan. You will see the added sales tax at checkout."
            }
          },
        {
            "@type": "Question",
            "name": "Can Hubble help me find a medical professional to make an appointment for a contact lens fitting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, our Doctor Locator can match you with a doctor in your area. As an FYI, if you find a doctor through Hubble, please know that they do not receive any compensation from Hubble and will only prescribe Hubble contact lenses for you if they’re a match for you. Please refer to this page for more information about the doctors in your area."
            }
          },
        {
            "@type": "Question",
            "name": "If I go to one of your recommended doctors, do I have to pay for my appointment?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes. Like any doctor visit, you are responsible for the cost of your appointment. However, many of these doctors accept vision insurance if you have coverage."
            }
          },
          {
            "@type": "Question",
            "name": "Do I need a contact lens prescription to place an order online?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, all online orders require a valid contact lens prescription from a doctor, including your name; the date of contact lens prescription expiration; the prescriber's name; and, for the contact lenses themselves, power, base curve, manufacturer, diameter, and material. All parts of your contact lens prescription must match the prescription contact lenses you ordered."
            }
          },
        {
            "@type": "Question",
            "name": "Can I order contact lenses online if I don't have a physical copy of my prescription?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can still place an order online for affordable daily contact lenses from Hubble if you don’t have a copy of your prescription. If you know your prescription for contact lenses info (check the side of your contact lens box for your current Rx) you can provide it to us during the checkout process, along with the name of your eye care provider or clinic. We will reach out to your eye care provider. If you do not provide us with a copy of your prescription, you may order and receive contact lenses other than those you were prescribed since we rely on information from you and your eye care provider to complete your order."
            }
          },
      {
            "@type": "Question",
            "name": "Can I use a prescription from a doctor outside the U.S.?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "At this time, Hubble cannot honor prescriptions from doctors outside the U.S."
            }
          },
      {
            "@type": "Question",
            "name": "Where are Hubble contact lenses made?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our lenses are made by our partner, St. Shine, in their state-of-the-art manufacturing facility in Taipei, Taiwan."
            }
          },
      {
            "@type": "Question",
            "name": "What power ranges in daily prescription contact lenses does Hubble offer?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble currently offers contact lenses with powers ranging from -0.50 to -12.00 and +0.5 to +6.00."
            }
          },
      {
            "@type": "Question",
            "name": "Does Hubble sell Multifocal/Bifocal contact lenses?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble does not currently not sell multifocal/bifocal contact lenses. However, you can shop hundreds of products from our sister site ContactsCart"
            }
          },
      {
            "@type": "Question",
            "name": "Does Hubble sell bi-weekly or monthly contact lenses?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble only sells daily contact lenses because wearing a new pair of contact lenses every day is the safest and most comfortable way to wear prescription contacts."
            }
          },
      {
            "@type": "Question",
            "name": "Does Hubble carry colored contact lenses?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Unfortunately, Hubble does not carry colored contact lenses."
            }
          },
          {
            "@type": "Question",
            "name": "Are Hubble contact lenses FDA approved?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, our lenses all have long standing FDA approval and we’ve sold over 600,000,000 prescription lenses!"
            }
          },
          {
            "@type": "Question",
            "name": "What are Hubble affordable disposable daily contact lenses made of?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our lenses are made of methafilcon-A hydrogel material, which possesses a 55% water content. They offer UV protection and feature a thin edge for comfort."
            }
          },
          {
            "@type": "Question",
            "name": "How do I log in to the Hubble Member Portal?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You can access our customer member portal at https://www.hubblecontacts.com/login/. Use your Hubble login credentials to track your order, adjust your subscription or shipment frequency, change your shipment address and more. You can also activate your account or reset your password via the member portal login page."
            }
          },
          {
            "@type": "Question",
            "name": "Does Hubble sell prescription contact lenses for people with astigmatism (toric lenses)?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble does not currently sell daily contact lenses to individuals with astigmatism. If you have astigmatism and are interested in Hubble Contacts, we recommend you talk about it with your eye care provider. For patients with astigmatism, you can shop hundreds of products from our sister site ContactsCart."
            }
          },
          {
            "@type": "Question",
            "name": "I still need assistance, how can I speak with someone about my order?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our Hubble customer service team is ready to help you with anything regarding your online contact lens, glasses, or accessory order with Hubble, via phone, email or chat. Feel free to give us a call at 1-844-334-1640, 7 days a week, from 9am - 9pm Eastern Time. You can also chat with us now for immediate service (look for the blue icon at the bottom right of your screen) or shoot us an email at help@hubblecontacts.com"
            }
          },
      {
            "@type": "Question",
            "name": "Can teenagers wear Hubble prescription daily contact lenses?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our lenses are approved for use for anyone age 18+, who has a valid prescription."
            }
          },
      {
            "@type": "Question",
            "name": "Can I use water or contact lens solution to clean or store my Hubble prescription daily contact lenses?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No! Hubble contact lenses are daily lenses, designed for individual use. Please do not attempt to wear them for more than one day."
            }
          },
      {
            "@type": "Question",
            "name": "How do I place a frames order on Hubble?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once you’ve selected your frames, you'll enter your prescription (if you're ordering prescription glasses) and select your preferred lens type. Hubble will take it from there— customizing your prescription glasses and shipping them directly to you. There are over 20 styles to choose from—so it may be hard to pick just one!"
            }
          },
      {
            "@type": "Question",
            "name": "How are Hubble glasses so affordable?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hubble cuts out the middleman: we design many of our glasses in-house and sell them directly to consumers (i.e. you). We also have strong partnerships with exceptional manufacturers who can accommodate our priority of keeping prices low and quality high."
            }
          },
      {
            "@type": "Question",
            "name": "Can I order multiple pairs of glasses with different prescriptions from HubbleContacts.com?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, absolutely! You can add as many pairs of glasses (and sunglasses) to your cart before checking out."
            }
          },
      {
            "@type": "Question",
            "name": "How long will it take for my glasses to arrive from Hubble? Can I check the status of my order?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Almost all orders from Hubble are delivered within 7-14 business days—depending on the complexity of your prescription. Once your order has shipped, you'll be sent a shipping confirmation email with a tracking link. You can also view order status in your member portal."
            }
          }

        ]
      }
  ]



  return (
    <Layout caLink="/faq-canada">
      <SEO
        title="Hubble Contact Lenses & Eye Glasses Frequently Asked Questions"
          description=" Discover answers to your frequently asked questions about Hubble contacts, glasses, and sunglasses. Find information on orders, subscriptions, prescriptions, and more."
          schemaMarkup={schemaMarkup}/>
      <StaticPageHeader title="FAQs" subheader="Have questions? We're here to help."/>
      <FaqSection/>
    </Layout>
  )
}

export default Faq
